/* Layout for admin dashboard skeleton */

.wrapper {
    align-items: stretch;
    display: flex;
    width: 100%;
}

#sidebar {
    width: 350px;
    /* min-width: 310px; */
    transition: all 0.35s ease-in-out;
    background: #fff;
}

aside{
    height: 100vh;
}

.dashboard_main{
    width: calc(100% - 310px);
}

.main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 0;
    overflow: hidden;
    transition: all 0.35s ease-in-out;
    width: 100%;

}

/* Sidebar Elements Style */

.sidebar-logo {
    padding: 1.15rem;
}

.js-sidebar.active {
    display: none;
}

.sidebar-logo a {
    color: #2196F3;
    font-size: 1.15rem;
    font-weight: 800;
    text-transform: uppercase;
}

.sidebar-nav {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    margin-left: 0;
    padding-right: 10px;
    margin-top: 0;
    padding-top: 20px;
}

.sidebar-header {
    color: #e9ecef;
    font-size: .75rem;
    padding: 1.5rem 1.5rem .375rem;
}

ul.sidebar-nav li.sidebar-item ul.sidebar-dropdown.active {
    display: block;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    padding-bottom: 10px;
}

ul.sidebar-nav li.sidebar-item ul.sidebar-dropdown.active li:hover a {
    background: #fff;
    color: #000;
    border-radius: 10px;
}

ul.sidebar-nav li.sidebar-item.active span svg {
    transform: rotate(90deg);
    top: 33%;
}

ul.sidebar-nav li.sidebar-item span svg {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

ul.sidebar-nav li.sidebar-item.active a {
    color: #fff;
}

ul.sidebar-nav li.sidebar-item.active {
    background: #3F51B5;
    border-radius: 10px;
}

ul.sidebar-nav li.sidebar-item ul.sidebar-dropdown {
    display: none;
}

a.sidebar-link:hover {
    background: #3F51B5;
    border-radius: 10px;
    color: #fff;
}

.fs-24{
    font-size: 24px;
}

ul.sidebar-nav li.sidebar-item {
    list-style: none;
    margin-bottom: 10px;
    margin: 0px 10px;
    margin-bottom: 12px;
    position: relative;
}

a.sidebar-link {
    padding: 10px 30px;
    color: #202224;
    position: relative;
    display: block;
    font-size: 14px;
    transition: .3s;
}

a.sidebar-link.active {
    background: #4880FF;
    color: #fff;
    border-radius: 10px;
}

.avatar {
    height: 40px;
    /* width: 40px; */
}


.content {
    flex: 1;
    max-width: 100vw;
    width: 100vw;
}

@media (min-width:768px) {
    .content {
        max-width: 100%;
        width: 100%;
        background: #F5F6FA;
    }
}

.card {
    box-shadow: 0 0 .875rem 0 rgba(34, 46, 60, .05);
    /* margin-bottom: 24px; */
}

.illustration {
    background-color: var(--bs-primary-bg-subtle);
    color: var(--bs-emphasis-color);
}

.illustration-img {
    max-width: 150px;
    width: 100%;
}

/* Sidebar Toggle */

#sidebar.collapsed {
    margin-left: -264px;
}

/* Footer and Nav */

@media (max-width:767.98px) {

    .js-sidebar {
        margin-left: -350px;
    }

    #sidebar.collapsed {
        margin-left: 0;
    }

    .navbar,
    footer {
        width: 100vw;
    }
}
@media (max-width:360.99px){
    .navbar,footer{
        width: 103vw;
    }
}

/* Theme Toggler */

@media screen and (min-width:700px){
    .mobile{
        display: none;
    }
}

.mobile .js-sidebar{
    margin-left: 0px !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    
}

.theme-toggle {
    position: fixed;
    top: 50%;
    transform: translateY(-65%);
    text-align: center;
    z-index: 10;
    right: 0;
    left: auto;
    border: none;
    background-color: var(--bs-body-color);
}

html[data-bs-theme="dark"] .theme-toggle .fa-sun,
html[data-bs-theme="light"] .theme-toggle .fa-moon {
    cursor: pointer;
    padding: 10px;
    display: block;
    font-size: 1.25rem;
    color: #FFF;
}

html[data-bs-theme="dark"] .theme-toggle .fa-moon {
    display: none;
}

html[data-bs-theme="light"] .theme-toggle .fa-sun {
    display: none;
}

/* Main start here */
main.content .card {
    background-color: #fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    box-shadow: 0 0.25rem 1.875rem rgba(46, 45, 116, 0.05);
    border: 1px solid #e9f9ff;
}

.bg-gradient-start-1 {
    background: linear-gradient(to right, #E6F9FF, #FEFFFF);
}

.bg-cyan {
    background-color: #2196F3 !important;
}

main.content .card .icon-bar {
    background: #009688;
    width: 50px;
    border-radius: 10px;
    height: 50px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
}

main.content .card h6 {
    font-size: 35px;
}

main.content .title-bar1 {
    padding: 20px 0;
}

main.content .title-bar1 h4 {
    margin:0;
    /* padding-bottom: 10px; */
    /* border-bottom: 1px solid black; */
}

main.content .title-bar1 ol.breadcrumb li {
    font-size: 14px;
    color: #838080;
}


.sidebar-logo{
    font-family: 'Poppins', sans-serif;
    /* font-size: ; */
}

.dashboardBtn{
    background-color: white;
    color: black;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px lightblue;
}

.dashboardBtn:hover{
    background-color: #3F51B5;
    color: white;
}

.table-responsive {
    overflow-x: auto;
  }
  
  .table th,
  .table td {
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis; /* Adds "..." when content is too long */
    overflow: hidden; /* Hide overflowed content */
  }

  .table-responsive {
    overflow-x: auto;
  }
/* End */

/* Sidebar width for mobile screens */
@media (max-width: 767px) {
    #sidebar {
        width: 250px; /* Reduced width on mobile */
    }

    .js-sidebar.active {
        display: block; /* Show sidebar when active */
        margin-left: 0;
    }

    /* Optionally add a collapsed state for smaller screens */
    #sidebar.collapsed {
        width: 0; /* Collapse sidebar */
        margin-left: 0;
    }

    .sidebar-logo {
        padding: 1rem; /* Adjust logo padding */
    }

    .sidebar-nav {
        padding: 10px 15px; /* Adjust padding for mobile */
    }

    .sidebar-link {
        font-size: 14px; /* Adjust font size for mobile */
        padding: 8px 20px;
    }

    .sidebar-item {
        margin: 5px 0; /* Reduce spacing between items */
    }

    /* Toggle button for sidebar visibility on mobile */
    .mobile .js-sidebar {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%; /* Full screen width on mobile */
    }
}

/* For extra small screens (360px or below) */
@media (max-width: 360px) {
    #sidebar {
        width: 220px; /* Further reduce width for very small screens */
    }
    .sidebar-link {
        font-size: 12px; /* Reduce font size for smaller screens */
    }
}

#sidebar {
    height: 100vh; /* Full viewport height */
    overflow-y: scroll; 
    overflow-x: hidden; 
    /* position: fixed; Keep the sidebar fixed on the screen */
    background-color: #f8f9fa; /* Sidebar background color */
    transition: all 0.3s; /* Smooth transition for toggling */
}

#sidebar::-webkit-scrollbar {
    width: 0; /* For Chrome, Safari, and Opera */
}

#sidebar .sidebar-dropdown {
    overflow: hidden; /* Prevent dropdown overflow */
    transition: max-height 0.3s ease-in-out; /* Smooth expand/collapse */
}

#sidebar .sidebar-dropdown.active {
    max-height: 500px; /* Adjust max height as needed */
}

.dashboardlogout:hover{
    background-color: darkred !important;
}