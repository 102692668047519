*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

$openSans: 'Open Sans', Helvetica, Arial, sans-serif;

body {
    background: #333;
    font-family: $openSans;
}

.postjobbuttonfooter {
    margin-top: 20px;
    border-radius: 30px;
}

$cyan: #007bff;
$dark: #FFFFFF;
$borderW: 2px;

.blob-btn {
    $numOfBlobs: 4;
    z-index: 1;
    position: relative;
    padding: 10px 20px;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
    color: $cyan;
    font-size: 16px;
    font-weight: bold;
    background-color: transparent;
    outline: none;
    border: none;
    transition: color 0.5s;
    cursor: pointer;
    border-radius: 30px;

    &:before {
        content: "";
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 1px solid $cyan;
        border-radius: 30px;
    }

    &:after {
        content: "";
        z-index: -2;
        position: absolute;
        left: $borderW*1.5;
        top: $borderW*1.5;
        width: 100%;
        height: 100%;

        transition: all 0.3s 0.2s;
        border-radius: 30px;
    }

    &:hover {
        color: $dark;
        border-radius: 30px;

        &:after {
            transition: all 0.3s;
            left: 0;
            top: 0;
            border-radius: 30px;
        }
    }

    &__inner {
        z-index: -1;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background: #ffffff;
    }

    &__blobs {
        position: relative;
        display: block;
        height: 100%;
        filter: url('#goo');
    }

    &__blob {
        position: absolute;
        top: $borderW;
        width: 100% / $numOfBlobs;
        height: 100%;
        background: $cyan;
        border-radius: 100%;
        transform: translate3d(0, 150%, 0) scale(1.7);
        transition: transform 0.45s;

        @supports(filter: url('#goo')) {
            transform: translate3d(0, 150%, 0) scale(1.4);
        }

        @for $i from 1 through $numOfBlobs {
            &:nth-child(#{$i}) {
                left: ($i - 1) * (120% / $numOfBlobs);
                transition-delay: ($i - 1) * 0.08s;
            }
        }

        .blob-btn:hover & {
            transform: translateZ(0) scale(1.7);

            @supports(filter: url('#goo')) {
                transform: translateZ(0) scale(1.4);
            }
        }
    }

}