/* Slider Container */
.slider-container {
    width: 100%;
    margin: 50px auto !important;
    padding: 30px;
    /* background-color: #28a745; */
    border-radius: 8px;
  }
  
  /* Slider Title */
  .slider-title {
    /* text-align: center; */
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }

  .more-trainers-link{
   color: white;
  }
  
  /* Card Style */
  .card {
    padding: 15px;
    margin-right: 10px !important;
    color: white;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    overflow: hidden;
  }

  .slick-dots{
    display: none !important;
  }
  
  /* Card Image */
  .card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  /* Card Content */
  .card-content {
    padding: 0 10px;
  }
  
  .card-title {
    font-size: 18px;
    margin-bottom: 10px;
    color: black;
  }
  
  .card-description {
    font-size: 14px;
    color: #777;
  }
  
  /* Responsive Styling */
  @media (max-width: 1024px) {
    .slider-container {
      width: 90%;
    }
  }
  
  @media (max-width: 768px) {
    .slider-container {
      width: 95%;
    }
  
    .card-image {
      height: 150px;
    }
  }

  /* Change the color of the default arrows */
.slick-prev::before, 
.slick-next::before {
    color:   #2991c8 !important; /* Change to your desired color */
    font-size: 24px !important; /* Adjust size if needed */
}

/* Optional: Style the arrow container */
.slick-prev,
.slick-next {
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

.slick-prev:hover,
.slick-next:hover {
    opacity: 1 !important;
}

  