@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  font-family: Arial, sans-serif;
}


header {
  background-color: #fff;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/* Style the search box inside the navigation bar */
.logo-icon input[type=text] {
  float: right;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-top: 8px;
  margin-right: 16px;
  font-size: 17px;
}


/* When the screen is less than 600px wide, stack the links and the search field vertically instead of horizontally */
@media (min-width: 780px) {
  .logo-icon input[type=text] {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;

  }

  .logo-icon input[type=text] {
    border: 1px solid #ccc;
    border-radius: 20px;
  }
}

.logo {
  display: flex;
  align-items: center;
}

.logo-text {
  color: #28a745;
  font-size: 24px;
  font-weight: bold;
}

.logo-icon {
  font-size: 24px;
  color: #28a745;
  margin-left: 5px;
}

.btn-danger {
  border-color: #761c19 !important;
  background-color: #c9302c !important;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.filter-container {
  position: sticky !important;
  top: 120px;
  background-color: #ffffff;
  /* Optional: Background color for better visibility */
  border: 1px solid #ccc;
  /* Optional: Add a border for better separation */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Optional: Add a subtle shadow */
  z-index: 10;
  /* Ensure it stays above other content */
}

.nav-links li {
  display: inline-block;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.rightTopHomeBanner {
  margin-bottom: -9px;
}

.nav-icon {
  margin-right: 5px;
  font-size: 20px;
}

.nav-links a:hover {
  color: #773070;
}

@media (max-width:780px) {

  .nav_parent {
    display: none;
  }

  .nav_parent_1 {
    display: block;
    width: 90vw;


  }

  .hamburger-icon {
    display: block;
  }

  .nav_parent_1 ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.hamburger-icon {
  display: none;
}

.banner-img-container {
  position: relative;
}

.hero-logo-container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-logo-container1 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-logo {
  max-width: 100%;
  /* Adjust as needed to scale the logo properly */
  height: auto;
}

.hero-logo1 {
  max-width: 100%;
  height: 100px;
}



.form-control::placeholder {

  padding-left: 10px;

}

.category-menu-item {

  padding: 10px;
  background-color: white;
  text-align: center;
  transition: box-shadow 0.3s ease;
}

.category-menu-item:hover {
  box-shadow: 0px 8px 16px #2991c8;
  /* Shadow effect */
  transform: translateY(-5px);
  /* Optional: Adds a small lift effect */
}

.categoryMenuList {
  margin: 0px 0px !important;
}

.category-menu-item img {
  border: 1px solid #2991c8;
  /* height: 86px !important; */
  /* background-color: #2991c8 !important; */
  margin: 0px;
}

.close-buttonLogin {
  background: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #000;
  border-radius: 8px;
}

.close-buttonLogin:hover {
  /* color:#ccc; */
  background-color: #2991c8;
}

@media (max-width: 576px) {
  .navbarSearchButton {
    display: none;
  }
}

@media (max-width: 576px) {
  .navbarToggleButton {
    margin-right: 20px;
  }
}

/* Modal styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  backdrop-filter: blur(15px);
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;

  text-align: center;
  position: relative;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content img {
  width: 200px;
  margin-bottom: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1rem;
  /* Reduced size */
  cursor: pointer;
  color: #333;
  /* Button color */
}

.modal-close-btn:hover {
  color: #000;
  /* Hover color change for better visibility */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Remove hover background color for "Jobs" and "Invest With Us" */
.navbar-nav .navjobinvest {
  margin: 0 10px;
  font-weight: 600;
  color: #333;
  position: relative;
  padding: 10px;
  transition: color 0.3s ease, text-shadow 0.3s ease;
  background-color: transparent;
  align-items: center;
}

.nav-item .navjobinvest:hover {
  text-shadow: 2px 2px 8px #2991c8;
  background-color: transparent !important;
}

/* Register Now and Login Button Styles */
.registerNow {
  background-color: #E86F2E !important;
  color: white !important;
  padding: 10px 25px;
  border-radius: 8px;
  text-shadow: none !important;
  margin: 10px 10px;
  font-weight: 600;
  position: relative;
  transition: color 0.3s ease, text-shadow 0.3s ease;
  background-color: transparent;
}

.navbar-navlist{
   font-size: 15px;
}

.registerNow::after {
  background: #E86F2E !important;
  box-shadow: 2px 2px 5px #2998c1;
}

.loginNow {
  background-color: #2998c1 !important;
  color: white !important;
  padding: 10px 25px;
  border-radius: 8px;
  text-shadow: none !important;
  margin: 10px 10px;
  font-weight: 600;
  position: relative;
  width: 150px;
  transition: color 0.3s ease, text-shadow 0.3s ease;
  background-color: transparent;
}

.loginNow:hover {
  background-color: #2991c8 !important;
}

.loginNow::after {
  background: none !important;
  box-shadow: 2px 2px 5px #29918c;
}

.register-now {
  background-color: #33c129 !important;
  color: white !important;
  padding: 10px 25px;
  border-radius: 8px;
  text-shadow: none !important;
  margin: 10px 10px;
  font-weight: 600;
  position: relative;
  width: 150px;
  transition: color 0.3s ease, text-shadow 0.3s ease;
  background-color: transparent;
}

.register-now:hover {
  background-color: #3ec829 !important;
}

.register-now::after {
  background: none !important;
  box-shadow: 2px 2px 5px #29918c;
}

/* New Login Modal styling */
.modal1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  backdrop-filter: blur(15px);
}

.login-modal {
  background: white;
  padding: 30px;
  border-radius: 10px;
  width: 500px;
  padding-top: 20px;
  text-align: center;
  position: relative;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content1 {
  text-align: left;
  position: relative;
}

.logo-left {
  position: relative;
  top: 50px;
  left: 0;
  width: 130px;
  /* Adjust as per your logo size */
}

.logo-leftforgot {
  /* position: relative; */
  top: 50px;
  left: 0;
  width: 130px;
  /* Adjust as per your logo size */
}

.modal-close-btn1 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.modal-close-btn1:hover {
  color: #000;
}

.login-title {
  margin-top: 50px;
  font-size: 24px;
  font-weight: bold;
}

.login-subtitle {
  color: #888;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input[type="text"] {
  border: 2px solid gray !important;
  font-weight: 500px !important;
  padding: 10px;
  font-size: 16px !important;
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  appearance: auto !important;
}

.btn-login {
  background-color: #2998c1;
  color: white;
  padding: 10px 0;
  margin-right: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}


.btn-login:hover {
  background-color: #2991c8;
}

.or-text {
  margin: 15px 0;
  color: #888;
}

.btn-google {
  background-color: #fff;
  color: #555;
  padding: 10px 0;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.btn-google img {
  vertical-align: middle;
  margin-right: 8px;
}

.skip-btn {
  display: inline-block;
  margin-top: 20px;
  color: blue;
  background-color: white;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

.skip-btn:hover {
  background-color: white;
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.headerlogin {
  display: flex;
  flex-direction: row;
}

.logotext {
  padding-left: 35px;
}



/* Suggestions Dropdown */
.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  list-style: none;
  margin: 0;
  padding: 0;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

/* Optional: Autocomplete customization if using react-autocomplete */
.react-autocomplete-input {
  width: 100%;
  padding: 10px;
  border-radius: 50px;
  border: 1px solid #ced4da;
}

.react-autocomplete-input:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}

.autocomplete-dropdown-container div {
  padding: 10px;
  cursor: pointer;
}

.autocomplete-dropdown-container div:hover {
  background-color: #f0f0f0;
}

.nav-link {
  width: 130px;
}

.investWithUs {
  padding: 5px 5px !important;
}

/* venderlistingcard */


.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #2991c8;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #eee;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-top: 20px;
  /* Adjust based on your navbar height */
}

.card-container:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.card-content {
  /* display: flex; */
  width: 100%;
}

.image-box {
  flex-basis: 25%;
  padding-right: 15px;
}

.business-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.info-box {
  flex-basis: 75%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.business-name {
  font-size: 18px;
  font-weight: bold;
}

.icon {
  margin-right: 10px;
}

.ratings {
  display: flex;
  align-items: center;
}

.get-direction:hover {
  background-color: green !important;
}

.rating {
  color: green;
  font-weight: bold;
  margin-right: 5px;
}

.ratings-text {
  margin-left: 5px;
  color: #555;
}

.badge {
  background-color: green;
  color: white;
  padding: 3px 7px;
  border-radius: 5px;
  margin-left: 10px;
}

.location {
  margin: 10px 0;
  color: #333;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tag {
  background-color: lightpink;
  padding: 5px 10px;
  font-weight: 500;
  border-radius: 5px;
}

.cta {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.recent-enquiries {
  margin-top: 10px;
  font-size: 12px;
  color: gray;
}

/* New mobile styling for centering */
.container {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.empty-vender {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(15px);
}

.empty-vender-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.empty-vender-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
}

.empty-vender-title {
  font-size: 1.5rem;
  margin-left: 30px;
}

.empty-vender-close-button {
  font-size: 1.5rem;
  cursor: pointer;
  border: none;
  background: none;
}

.empty-vender-body {
  margin: 20px 0;
}

.empty-vender-footer {
  display: inline-block;
  justify-content: flex-end;
  padding-top: 10px;
}

.btn-secondary {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #6c757d;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
}

.btn-secondary:hover {
  background-color: #8d9ead;
}


/* Trainer Payment form */


/* Container Styling */
.checkout-container {
  padding: 20px;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

/* Banner Styling */
.checkout-banner {
  background: url("./assets/trainerFormBg.jpg") no-repeat center center / cover;
  width: 100%;
  padding: 40px;
  text-align: center;
  color: #fff;
  transition: transform 0.3s ease;
}

.checkout-banner.shifted-banner {
  transform: translateY(150px);
  /* Move the banner down when the form is visible */
}

.checkout-banner-content {
  max-width: 1000px;
  margin: auto;
}

.checkout-banner-button {
  background-color: #4285f4;
  color: #fff;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  font-size: 16px;
}

.checkout-banner-button:hover {
  background-color: #2ecc71;
}

/* Modal Overlay */
.checkout-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #649c7c 60%, #27ae60 40.1%);
  z-index: 1998;
  backdrop-filter: blur(15px);
}

/* Checkout Form */
.checkout-form-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 1999;
  width: 90%;

  max-width: 800px;
}


.checkout-form {
  width: 100%;
}

.checkout-title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
}

.checkout-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.checkout-col {
  flex: 1 1 45%;
}

.checkout-inputBox {
  margin-bottom: 15px;
}

.checkout-inputBox span {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
}

@media (max-width: 576px) {
  .checkout-inputBox span {
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
  }

  .checkout-title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .imgCard {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd !important;
    border-radius: 6px;
    background-color: #f9f9f9;
    font-size: 14px;
  }
}

.checkout-inputBox input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
}

.checkout-flex {
  display: flex;
  gap: 10px;
}

.checkout-submit-btn {
  background-color: #27ae60;
  color: #fff;
  padding: 12px;
  border: none;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.checkout-submit-btn:hover {
  background-color: #2ecc71;
}

.checkout-close-btn {
  background: #f44336;
  color: #fff;
  padding: 12px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.checkout-close-btn:hover {
  background: #e53935;
}

.checkout-banner-content p {
  font-size: 18px;
  color: white;
}


.catelogueimg {
  max-width: 100%;
  width: 180px;
  height: 200px !important;
}

.productContainer h6 {
  margin-bottom: 0;
}

.selectStaCitCat {
  font-size: 14px !important;
  padding: 12px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
section,
select,
ul,
i,
li,
img,
div {
  /* pointer-events: none !important; */
  user-select: none;
}

.VendorMainDashboard {
  user-select: text !important;
}

/* body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background: #f9f9f9;
  }*/

h2 {
  color: #333;
}

.job-post-form,
.job-approval {
  /* margin: 20px auto; */
  max-width: 600px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.job-post-form input,
.job-post-form select {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.job-post-form input[type=checkbox] {
  width: auto !important;
}


.radio-group {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.radio-group button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background: #f9f9f9;
  cursor: pointer;
  color: #000;
}

.radio-group button.selected {
  background: #007bff;
  color: rgb(7, 7, 7);
}

.next-button {
  background: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.job-card {
  background: #fff;
  padding: 15px;
  margin: 10px 0;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.filetype {
  font-size: 10px !important;
  height: 50px !important;
}


.job-post-form {
  max-width: 1000px;
  margin: 2rem auto;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.form-header {
  background-color: #fff8e5;
  padding: 1.5rem;
  text-align: center;
  font-size: 1.2rem;
  color: #555;
  font-weight: bold;
  border-bottom: 1px solid #f1f1f1;
}

.form-header p {
  margin: 0;
}

form {
  padding: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.button-group {
  display: flex;
  gap: 10px;
}

.button-group button {
  flex: 1;
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  color: #333;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.button-group button.active {
  background-color: #007bff;
  color: black;
  border-color: black;
}

.button-group button:hover {
  background-color: #e9ecef;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 0.5rem;
}

.checkbox label {
  font-size: 0.9rem;
  color: #555;
}

.exp-fields {
  display: flex;
  gap: 10px;
}

.exp-fields input {
  flex: 1;
}

.whatsapp {
  color: #25d366;
  font-weight: bold;
}

.submit-btn {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .job-post-form {
    margin: 1rem;
  }
}


.selectjobpost:hover {
  background-color: #d66e6e !important;
  /* color: rgb(247, 6, 6) !important; */
}

.form-headingh5 {
  background-color: #FFFBEB;
  padding: 40px !important;
  margin: 0px !important;
  border-radius: 10px;
}


.form-headingh5 img {
  width: 80px;
  max-width: 100%;
}

.jobpostsubmitbutton {
  width: 30px;
  /* float: right; */
}

.jobpostbuttonupper {
  float: right;
}


/* Wrapper container */
.jobsListing-container {
  display: flex;
  margin: 20px;
  font-size: 14px;
  /* width: 60%; */
  justify-content: space-between;
  gap: 20px;
}

/* Container for the job cards */
.jobCards-container {
  flex: 3;
}

.minExpReq {
  margin-right: 4px;
}

/* Container for the filter */
.filter-container {
  flex: 1;
  padding: 20px;
  /* background-color: #f4f4f4; */
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.filter-container label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.filter-container select,
.filter-container input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.filter-container input {
  margin-top: 5px;
  font-size: 14px;
}

/* Styling for the job cards */
.jobCard {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.jobCard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jobCard-title {
  font-size: 18px;
  font-weight: bold;
}

.jobCard-company1 {
  font-weight: 600;

}


.jobCard-salary {
  /* margin-top: 10px; */
  color: rgb(21, 156, 21);
  font-weight: 700;
  font-size: 18px;
}

.jobCard-details {
  margin-top: 10px;
}

.jobCard-tags {
  margin-top: 10px;
  color: #2998c1;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  /* color: white; */
  /* padding: 10px; */
}

.jobCard-footer {
  margin-top: 10px;
}

.jobCard-verified {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.jobCard-verifiedIcon {
  margin-right: 5px;
}

.jobCard-badge {
  background-color: #ff0;
  color: #000;
  padding: 5px;
  font-size: 14px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.no-jobs {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}



.vendorIdOnTop {
  text-align: right;
}

.tableHeader {
  text-align: center !important;
}

.slider-container {
  margin-top: 20px;
  padding: 20px;
}

.slider-title {
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  /* Center the title */
  align-items: center !important;
}

.slider-title h2 {
  align-items: center !important;
  font-size: 2rem;
  font-weight: 500;
  color: #000;
}

.more-trainers-link {
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  background-color: #2991c8;
  padding: 10px 20px;
  float: right;
  border-radius: 5px;
  margin-top: 20px;
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.more-trainers-link:hover {
  background-color: #e86f2e;
  color: white;
  text-decoration: none;
}

.card {
  padding-bottom: 20px;
}

.btntrainer {
  background-color: #2991c8;
  padding: 8px;
  border-radius: 5px;
  color: white;
  margin-bottom: 20px !important;
}

.btntrainer:hover {
  background-color: #e86f2e;
  color: white;
}

.myslider {
  padding: 10px;
}

.card-image {
  object-fit: contain;
  width: 100%;
  height: 250px;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.card-content {
  text-align: center;
  padding-top: 10px;
}

.card-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0px;
}

.card-description {
  color: #6c757d;
}

.more-services-link {
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  background-color: #2991c8;
  transition: background-color 0.3s ease, color 0.3s ease;
  float: right;
}

.more-services-link:hover {
  background-color: #E86F2E;
  color: white;
  text-decoration: none;
}

.card-img-top {
  height: auto;
  border-radius: 10px;
}

.sportacademyCArd {
  object-fit: cover !important;
}

.container {
  margin-top: 20px;
}

.row-gap-4 {
  row-gap: 1.5rem;
}

.btn-primary {
  background-color: #007bff !important;
  border-color: #007bff !important;
}

.btn-primary:hover {
  background-color: green;
  border-color: #004085;
}

.checkservice {
  align-items: center !important;
  background-color: #2991c8;
  color: white;
  padding: 8px;
}

.checkservice:hover {
  background-color: #E86F2E;
  color: white;
}

@media (max-width: 992px) {

  /* Tablet and below */
  .custom-cardAcademy {
    width: 100% !important;
    margin-bottom: 20px;
    /* Add space between cards */
  }
}

@media (min-width: 992px) {

  /* Laptops and above */
  .custom-cardAcademy {
    width: 20% !important;
    /* Ensure 20% width for larger screens */
    margin-bottom: 20px;
  }
}

.card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Optional: Add shadow for aesthetics */
}

.checkservice {
  text-decoration: none;
  padding: 10px 15px;
  color: #fff;
  background-color: #007bff;
  display: inline-block;
  border-radius: 5px;
}

.vendorCard-container {
  border: 1px solid #2991c8;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.vendorCard-content {
  display: flex;
  width: 100%;
}

.vendorCard-header {
  margin-top: 15px;
}

.vendorCard-body {
  display: flex;
  flex-direction: column;
}

.vendorCard-details {
  margin-bottom: 10px;
}

.vendorCard-title {
  font-size: 20px;
  font-weight: bold;
}

.vendorCard-location {
  color: #555;
}

.vendorCard-company {
  color: #333;
}

.vendorCard-tags {
  margin-top: 5px;
}

.vendorCard-tag {
  display: inline-block;
  background-color: #f1f1f1;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 5px;
}

.minExpReq {
  font-weight: bold;
}

.vendorCard-footer {
  display: flex;
  flex-direction: column;
}

.vendorCard-company1,
.vendorCard-benefits {
  margin-top: 10px;
}

.vendorCard-extra {
  margin-top: 15px;
}

.vendorCard-call,
.vendorCard-whatsapp {
  margin-right: 10px;
}

.iconButton {
  width: 40px;
  height: 40px;
  background-color: #f1f1f1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconButton-hover {
  background-color: #ddd;
}

.getDirectionButton {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 15px;
}

.vendorCard-verified {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.vendorCard-verifiedIcon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.vendorListingCard {
  width: 65%;
}

.slick-arrow,
.slick-prev {
  display: none !important;
}

.slick-slider,
.slick-slider img {
  padding: 0px !important;
}

.Homepagesearch {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 7px;
  background-color: rgb(41, 145, 200);
  color: white;
  font-size: 18px;
}

.homeBannerMain {
  justify-items: center !important;
}

/* Custom CSS for Navbar */
.navbar-logo {
  vertical-align: baseline;
  width: 250px;
  height: 60px;
}

/* For Search Bar */
.search-bar {
  border-radius: 50px;
  width: 75%;
  /* Default width */
}

/* Adjust Search Bar on smaller screens */
@media (max-width: 767px) {
  .search-bar {
    width: 100%;
    /* Full width on small screens */
  }
  .navbarul li{
    margin-top: 20px;
  }
  
}

/* Navbar Toggler for Mobile */
.navbar-toggler {
  border-color: transparent;
}


/* Make navbar links align in the center */
.navbar-nav {
  justify-content: center;
}

/* For mobile: increase size of search button */
.search-button {
  border-radius: 50px;
}

/* Suggestions List */
.suggestions-list {
  list-style-type: none;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  z-index: 1000;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f1f1f1;
}

@media (min-width:250px) and (max-width:800px) {
  .navSearchbar {
    display: none !important;
  }
}

/* Mobile View: Filter should be above job cards */
@media (max-width: 767px) {
  .jobsListing-container {
    flex-direction: row-reverse;
  }

  .filter-container {
    position: sticky !important;
    top: 120px;
    background-color: #ffffff;
    /* Optional: Background color for better visibility */
    border: 1px solid #ccc;
    /* Optional: Add a border for better separation */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Optional: Add a subtle shadow */
    z-index: 1000;
    /* Ensure it
    }

    .jobCards-container {
        order: 2; /* Job cards come second */
  }
}

.slick-slide img {
  display: inline-grid;
  object-fit: cover;
}

.class-item-thumbnail img {
  height: 100px !important;
  max-width: 100px;
  max-height: 100px;
}

.sportacademyCArd img {
  object-fit: cover;
}

.joFormMain button {
  background-color: white;
  color: #000;
}

.joFormMain button:hover {
  background-color: rgb(214, 110, 110);
  color: white;
}

.selectskilss:hover {
  color: white;
}

.selectskilss {
  color: white !important;
}

/* Apply default width of 75% for the vendor card and align it to the left */
.vendor-card {
  width: 75%;
  margin-left: 0;
  /* Align to the left */
  margin-right: auto;
  /* Keep it aligned to the left */
}

@media (max-width: 768px) {

  /* Make the card responsive on smaller screens */
  .vendor-card {
    width: 100%;
    /* 100% width on mobile */
    margin-left: 0;
    /* Ensure it’s aligned to the left */
  }
}

.registerformheader {
  flex-direction: row;
}


@media(max-width: 780px) {
  .navbarkhiladi-logo {
    width: 120px;
  }

  .homenavbar-icon {
    width: 50px;
  }

  .homeSidebar-icon {
    width: 30px;
  }
}

@media(max-width: 780px) {
  .createjobheader{
    display: block !important;
    margin-left: 0px !important;
    justify-content: center;
  }
  .createjobheader img{
    width: auto !important;
    height: 50px !important;
    align-items: center !important;
  }
  .createjobheader .createjobwelcometext{
    font-size: 14px;
  }
}

.logo-left14{
  height: auto;
  width: 230px;
}

.categoryshorttext p{
  font-size: 14px;
}

/* ------------------------------services start here---------------------------- */


:root {
  --white-color: #fff;
  --black-color: #000;
  --background-color: #E86F2E;
  /* --yellow-color: #FC9C19; */
  --light-eee: #eee;
  --pink-color: #EC85CF;
}


.service-card {
  padding: 25px;
  border-radius: 12px;
  background-color: var(--background-color);
  width: 100%;
  height: 200px;
  overflow: hidden;
  border: 2px solid #E86F2E;
  /* box-shadow: 7px 7px 20px #2991c8; */
  
}

.service-card:hover{
  /* background-color: #E86F2E; */
  box-shadow: 7px 7px 20px #E86F2E;
  color: #2991c8 !important;
}

.service-card h1 {
  font-size: 20px;
  font-weight: bold;
  color: #eee;
  float: left;
  text-align: start;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.service-card:hover h1{
  color: black;
}


.service-card p {
  color: #eee;
  float: left;
}

.services-img {
  width: 150px;

}

@media (max-width:567px) {
  .service-card {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      align-items: center;
      justify-content: center;
  }

  .service-card h1 {
      text-align: center;
      border:none !important;
  }
}

a{
  text-decoration: none;
}
/* Center the title */
.title2 {
  text-align: center; /* Centers the title content horizontally */
  margin-bottom: 30px;
  margin-top: 160px; /*Adds some space below the title*/
}

/* Style for the button */
#Second-button {
  float: right; /* Aligns the button to the right */
  background-color: #2991c8; /* Primary button color */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

/* Hover effect for the button */
#Second-button:hover {
  background-color: #E86F2E !important; /* Changes to green on hover */
  color: white; /* Ensures the text stays white on hover */
}

/* Center button container */
.center-button {
  text-align: right; /* Ensures the button is right-aligned within the container */
  margin-top: 30px;
}


.service-card2{
  background-color: #2991c8;
}

.latest-news {
  padding: 20px 0;
  margin-top: 40px;
  margin-bottom: 40px;
  /* margin: 40px; */
}

.latest-news .post-entry {
  position: relative;
  overflow: hidden;
}

.latest-news .post-entry img {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.latest-news .post-entry:before {
  content: "";
  width: 0;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  background-color: #ee1e46;
  z-index: 2;
}

.latest-news .post-entry .caption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  z-index: 1;
}

.latest-news .post-entry .caption:before {
  content: "";
  position: absolute;
  top: 0;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  background: rgba(0, 0, 0, 0.4);
}

.latest-news .post-entry .caption .caption-inner {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
}

.latest-news .post-entry .caption .caption-inner h3 {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.latest-news .post-entry .author .img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40px;
  flex: 0 0 40px;
}

.latest-news .post-entry .author .img img {
  max-width: 100%;
  border-radius: 50%;
}

.latest-news .post-entry .author .text h4 {
  color: #fff;
  font-weight: 700;
}

.latest-news .post-entry .author .text span {
  color: rgba(255, 255, 255, 0.5);
}

.latest-news .post-entry .author .text h4,
.latest-news .post-entry .author .text span {
  margin: 0;
  line-height: 1;
  font-size: 14px;
}

.latest-news .post-entry:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.latest-news .post-entry:hover .caption:before {
  background: rgba(0, 0, 0, 0.7);
}

.latest-news .post-entry:hover:before {
  width: 100%;
}


/* Main container styling */
.about-section {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: #fff; /* Adjust the background color */
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Left column for video */
.video-container {
  /* flex: 1; */
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Right column for text */
.about-text {
  flex: 1;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

.about-text h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.about-text p {
  margin-bottom: 0;
  color: #555;
}


.selected {
  background-color: #d66e6e !important;
  color: white !important;
  border-color: #d66e6e;
}

.unselected {
  color: #000 !important;
  border-color: #ccc;
}


.vendertableform th{
  text-align: center !important;
}

.homebuttonNav{
  background-color: #2998c1;
  color: white;
  font-weight: 600;
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.2);
  transition: box-shadow 0.3s ease;
}

.homebuttonNav:hover{
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.4);
}

.homebuttonNav1{
  background-color: #2998c1;
  color: white;
  font-weight: 600 !important;
  padding: 10px;
  font-size: 16px !important;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.2);
  transition: box-shadow 0.3s ease;
}

.homebuttonNav1:hover{
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.4);
}

.footersvg{
  display: none;
}

.postjobbutton {
  border-radius: 4px;
  background-color: #f4511e;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 18px;
  padding: 15px;
  width: 120px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.postjobbutton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.postjobbutton span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.postjobbutton:hover span {
  padding-right: 25px;
}

.postjobbutton:hover span:after {
  opacity: 1;
  right: 0;
}

.forgotpassword{
  font-size: 16px;
  border: 1px solid rgb(52, 50, 50);
  color: black;
  padding: 10px 0;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}


.or-container {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
}

.line {
  flex-grow: 1;
  border: none;
  border-top: 1px solid #ccc;
  margin: 0 10px;
}

.or-text {
  font-weight: 400;
  color: #000;
}

.resetpasswordbutton{
  margin: 0 25%;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  background-color: #2991c8;
  color: white;
  padding: 10px;
  border-radius: 50%;
  transition: background 0.3s ease;
}

.slider-arrow:hover {
  background-color: #5bb9ec;
}

.prev {
  left: -40px;
}

.next {
  right: -40px;
}

@media (max-width:780px){
  .vender-listing-title{
    font-size: 14px !important;

  }
  .listing-getdirection{
    font-size: 14px !important;
  }
}

.jobCard-timestampa{
  font-weight: 400;
}

.createdateofjob{
  color: rgb(0, 119, 255);
  font-weight: bold;
}

