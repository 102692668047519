/* Fullscreen blur overlay for background */
.contact-form-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
  overflow-y: auto;
  padding: 20px; 
}

.contact-form {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.6);
  max-width: 500px;
  border: 1px solid rgb(6, 143, 193);
  width: 100%;
  max-height: 90vh; 
  overflow-y: auto;
}

@media(max-width:780px){
  .contact-form{
    max-height: 70vh;
  }
}


.form-title {
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
  color: #000;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  /* margin-bottom: 15px; */
}

.form-group {
  width: 100%;
}

label {
  display: block !important;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  text-align: left;
}

input[type="text"],
input[type="email"],
input[type="tel"],
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd !important;
  border-radius: 6px;
  background-color: #f9f9f9;
  font-size: 14px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
select:focus {
  border-color: #2991c8 !important;
  background-color: #fff;
  outline: none;
}

.form-footer {
  text-align: center;
  margin-top: 20px;
}

@media (max-width:853.99px){
.registerMe .registerImg{
  position: fixed !important;
  right:0 !important;
  top:68% !important;
  z-index: 999 !important;
}} 


.submit-btn {
  background-color: #2991c8;
  color: white;
  border: none;
  padding: 12px 30px;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: green !important;
}

/* WhatsApp Button Style */
.openFormBtn {
  top: 80%;
  right: 1%;
  position: fixed;
  z-index: 999;
  width: auto;
  height: 60px;
  background-color: #E86F2E;
  border-radius: 22%;
  border: none;
  box-shadow: 5px 5px 15px black;
  cursor: pointer;
}

/* Optional styles for the WhatsApp button */
.openFormBtn img {
  width: 100%;
  /* Make sure the image fits inside the button */
  height: auto;
}

.close1-btn {
  background-color: red;
  color: white;
  border: none;
  padding: 12px 23px;
  /* position: relative;
  top: 0px;

  right: -100px; */
  border-radius: 10px;
  cursor: pointer;
}

.close1-btn:hover {
  background-color: darkred;
}

.registerMe {
  position: relative;
  cursor: pointer;
}
.registerMe .registerImg{
  position: fixed ;
  right:0 ;
  top:68% ;
  z-index: 999 ;
}

.popupclose{
  background-color: #656565;
  border-radius: 12px;
}

.popupclose:hover{
  background-color: #9a9393 !important;
}