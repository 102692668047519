.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.error-message {
  color: red;
  font-weight: bold;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.VenderBanner img{
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  height: 250px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* background-image: url(../src/assets/bg-vender.jpg); */
}

.VenderNameStart{
  padding: 4px;
  background-color: #c12929;
  width: 10px;
  height: 10px;
}

/* General Container Styling */
.container-xxl {
  padding: 3rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Section Title */
.section-title {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: white;
  color: #007bff;
  padding: 0.3rem 1rem;
  border-radius: 20px;
}

h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
}

/* Testimonial Item */
.testimonial-item {
  text-align: center;
}

.testimonial-item img {
  border: 2px solid #ddd;
  border-radius: 50%;
  padding: 5px;
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
}

.testimonial-item h5 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.testimonial-item p {
  font-size: 14px;
  color: #666;
  margin-bottom: 1rem;
}

/* Testimonial Text */
.testimonial-text {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 1.5rem;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
  position: relative;
}

.testimonial-text:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: #f8f9fa transparent transparent transparent;
}

/* Small Text for Sports Comments */
.testimonial-text small {
  color: #999;
  font-style: italic;
}

/* Owl Carousel Arrows */
.owl-nav {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.owl-nav button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.owl-nav button:hover {
  background-color: #0056b3;
}

.owl-dots {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.owl-dots .owl-dot {
  width: 12px;
  height: 12px;
  background-color: #ddd;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.owl-dots .owl-dot.active {
  background-color: #007bff;
}

/* Responsive Styling */
@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }

  .testimonial-text {
    font-size: 13px;
  }
}
