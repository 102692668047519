/* CSS Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    font-family: "Poppins", sans-serif;
    font-optical-sizing: auto;
    font-size: 18px;
    line-height: 1.6;
}

body {

    background-color: #fff;
    color: #333;
}

a {
    color: #007BFF;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul,
ol {
    padding-left: 20px;
    margin-bottom: 1.5rem;
}

/* Images */
img {
    max-width: 100%;
    height: auto;
}

/* Buttons */
button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #0056b3;
}



.theme-btn {
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    color: #fff;
    background: #000000;
    padding: 13px 20px;
    border-radius: 4px;
    display: inline-block;
    transition: all 0.4s;
    position: relative;
    text-align: center;
    z-index: 1;
}

.theme-btn:hover {
    color: #fff;
    transition: all 0.4s;
    transform: translateY(-5px);
}

.theme-btn:hover::after {
    width: 100%;
    transition: all 0.4s;
    right: auto;
    left: 0;
}

.theme-btn::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 0;
    right: 0;
    top: 0;
    transition: all 0.4s;
    background: #E7E9EB;
    opacity: 0.2;
    z-index: -1;
    border-radius: 4px;
}

.theme-btn i {
    margin-left: 4px;
    transform: rotate(-45deg);
}

/* =======================Login Register css start here=============== */
section.login-page-wrapper {
    background: #f5fbff;
}

.login-card {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;

    background-position: center;
    padding: 30px 12px;
}

.login-card .login-main {
    width: 508px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
    margin: 0 auto;
    background-color: #fff;
}

.login-card .login-main .theme-form h4 {
    margin-bottom: 5px;
}

.login-card .login-main .theme-form p {
    margin-bottom: 25px;
    font-size: 14px;
    color: #898989;
}

.login-card .login-main .theme-form .form-group {
    margin-bottom: 10px;
    position: relative;
}

.login-card .login-main .theme-form label {
    font-size: 14px;
}

.login-card .login-main .theme-form input {
    background-color: rgba(0, 102, 102, 0.1);
    transition: all 0.3s ease;
    padding: 11px;
    font-size: 15px;
}



.login-card .login-main .theme-form .checkbox label::before {
    background-color: #f9f9fa;
    border: 1px solid #dfdfdf;
}



.login-card .login-main .theme-form .link {
    position: absolute;
    top: 10px;
    right: 0;
}

.login-card .login-main .theme-form .link {
    position: absolute;
    top: 4px;
    right: 0;
    text-decoration: none;
    color: #000;
    font-size: 14px;
}

.login-card .login-main .theme-form .form-group .checkbox {
    padding-bottom: 20px;
}

.login-card .login-main .theme-form .form-group .checkbox label.text-muted {
    padding-left: 11px;
}

.checkbox.p-0.pb-10 {
    padding-bottom: 20px !important;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-right: 5px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

p.succesloging {
    text-align: center;
    padding: 20px;
    border: 1px solid #ddd;
    background: #e9ffe9;
    border-radius: 9px;
    border: 1px solid green;
    color: #000 !important;
}

/* =======================Login Register css end here=============== */
.totle-use h4 {
    font-size: 20px;
}

.totle-use h4 b {
    background: #ffc107;
    width: 40px;
    display: inline-block;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

table.table.table-striped.table-bordered {
    background: #fff;
}

table.table {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    padding: 45px !important;
}

main.content input.form-control {
    border: navajowhite;
    border: 1px solid #ddd;
    border-radius: 0;
}

.btn {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.225);
    border-radius: 5px;
}